







































































































































.logs2 {
  .el-tabs__header {
    margin: 0;
  }

  /deep/ .el-tabs__item.is-active {
    font-size: 20px;
    color: #2c3e50;
    font-weight: bold;
  }
}

.table_h5 {
  td {
    border: 1px solid #ccc;
  }
}
